<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!--------------------------------------STEPPER-------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-car"
              >
                AUTO INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <!------------------------------------------------FORM---------------------------------->
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Client Details
                  </div>
                </v-col>
              </v-row>
              <!---------------------POLICY DETAILS----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Policy Details
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-between">
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <v-dialog
                    ref="dialog1"
                    v-model="modal1"
                    :return-value.sync="effectiveDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Effective Date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="effectiveDate"
                          label="Effective Date"
                          prepend-icon="mdi-calendar"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                          @click="clickedFieldGlobal('effectiveDate', 1, 'Auto Survey v1')"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="effectiveDate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal1 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog1.save(effectiveDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <!---------------------PERSONAL DETAILS----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Personal Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="firstName"
                      label="First Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('firstName', 2, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="lastName"
                      label="Last Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('lastName', 3, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-select
                    v-model="suffix"
                    label="Suffix"
                    :items="suffixes"
                    @click="clickedFieldGlobal('suffix', 4, 'Auto Survey v1')"
                  ></v-select>
                </v-col>
                <v-col cols="7">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="dob"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="dob"
                          label="Date of Birth"
                          prepend-icon="mdi-calendar"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                          @click="clickedFieldGlobal('dob', 5, 'Auto Survey v1')"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="dob"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(dob)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <!---------------------STREET ADDRESS----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Street Address
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map"
                    ref="address"
                    classname="form-control"
                    outlined
                    placeholder="Please enter garaging address"
                    country="us"
                    @placechanged="getAddressData"
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Garaging Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="garagingAddress"
                      label="Garaging Address (P.O. Box Not Allowed)"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('address', 6, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="suite"
                    label="Apartment, suite, etc."
                    @click="clickedFieldGlobal('suite', 7, 'Auto Survey v1')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="city"
                      label="City"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('city', 8, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-text-field
                      v-model="state"
                      label="State"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('state', 9, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="zip"
                      v-mask="'#####'"
                      label="Zip"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('zip', 10, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Residence Ownership"
                    rules="required"
                  >
                    <v-select
                      v-model="ownResidence"
                      label="Do you own your primary residence?"
                      :error-messages="errors"
                      :items="residenceOptions"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col>
                  <div class="sub-heading">
                    Is this the garaging address for all vehicles?
                  </div>
                  <v-radio-group
                    v-model="garaging"
                    mandatory
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col>
                  <v-checkbox
                    v-model="checkbox"
                    label="At current address LESS THAN 2 YEARS"
                    class="sub-heading"
                  >
                  </v-checkbox>
                </v-col>
              </v-row> -->
              <!----------------------PREVIOUS ADDRESS--------------------->
              <!-- <div v-if="checkbox">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-2">
                      Previous Street Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Previous Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="previousAddress"
                        label="Previous Address"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="previousSuite"
                      label="Apartment, suite, etc."
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="previousCity"
                        label="City"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-text-field
                        v-model="previousState"
                        label="State"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="previousZip"
                        v-mask="'#####'"
                        label="Zip"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <---------------------ADDITIONAL INFO----------------------->
              <!-- <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Additional Info
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-heading">
                    Insured for the past 6+ months with less than 30 days lapse?
                  </div>
                  <v-radio-group
                    v-model="lapsed"
                    mandatory
                  >
                    <v-radio
                      label="Yes"
                      value="Yes"
                    >
                    </v-radio>
                    <v-radio
                      label="No"
                      value="No"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="lapsed === 'No'">
                <v-col cols="6">
                  <div class="sub-heading">
                    Reason for no prior insurance
                  </div>
                  <v-select
                    v-model="reason"
                    :items="reasons"
                    label="Select One"
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Vehicle Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    v-mask="'####'"
                    name="Year"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="vehicleYear"
                      label="Vehicle Year"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('vehicleYear', 11, 'Auto Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="vehicleType"
                      label="Vehicle Type"
                      :error-messages="errors"
                      :items="vehicleTypes"
                      @click="clickedFieldGlobal('vehicleType', 12, 'Auto Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Make"
                    rules="required"
                  >
                    <v-select
                      v-model="make"
                      label="Make"
                      :error-messages="errors"
                      :items="makes"
                      @click="clickedFieldGlobal('vehicleMake', 13, 'Auto Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Model"
                    rules="required"
                  >
                    <v-select
                      v-model="model"
                      label="Model"
                      :error-messages="errors"
                      :items="models"
                      @click="clickedFieldGlobal('vehicleModel', 14, 'Auto Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Body Style"
                    rules="required"
                  >
                    <v-select
                      v-model="bodyStyle"
                      label="Body Style"
                      :error-messages="errors"
                      :items="bodyStyles"
                      @click="clickedFieldGlobal('bodyStyle', 15, 'Auto Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Vehicle Use"
                    rules="required"
                  >
                    <v-select
                      v-model="vehicleUse"
                      label="Vehicle Use"
                      :error-messages="errors"
                      :items="vehicleUses"
                      @click="clickedFieldGlobal('vehicleUse', 16, 'Auto Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    large
                    :disabled="invalid"
                    color="primary"
                    class="mb-2 font-weight-bold"
                    @click="next"
                  >
                    <v-icon
                      left
                    >
                      mdi-car
                    </v-icon>
                    GET A QUOTE
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'AutoInfo',
    data () {
      return {
        firstName: 'Steve',
        lastName: 'Vai',
        city: 'Encino',
        state: 'CA',
        zip: '91316',
        suite: '',
        garagingAddress: '4816 Encino Ave',
        ownResidence: '',
        reason: '',
        effectiveDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dob: '1960-06-06',
        checkbox: false,
        garaging: '',
        lapsed: '',
        suffix: '',
        suffixes: ['JR', 'SR', 'I', 'II', 'III', 'IV'],
        residenceOptions: ['Yes, it is a Single Family Dwelling', 'Yes, it is a Condo or Townhome', 'No, I rent my Primary Residence'],
        reasons: ['Did not own a vehicle', 'Driving without insurance', 'Military', 'Lived outside USA', 'Previous company car', 'Vehicle not operational'],
        vehicleYear: '2022',
        vehicleType: 'Car',
        vehicleTypes: ['Car', 'Pickup', 'SUV', 'Van'],
        make: 'Make3',
        makes: ['Make1', 'Make2', 'Make3'],
        model: 'Model2',
        models: ['Model1', 'Model2', 'Model3'],
        bodyStyle: 'Body1',
        bodyStyles: ['Body1', 'Body2', 'Body3'],
        vehicleUse: 'Use1',
        vehicleUses: ['Use1', 'Use2', 'Use3'],
        modal1: false,
        modal2: false,
        dialog1: false,
        dialog2: false
      }
    },
    computed: {
      autoDetails () {
        return {
          effectiveDate: this.effectiveDate,
          firstName: this.firstName,
          lastName: this.lastName,
          city: this.city,
          state: this.state,
          zip: this.zip,
          suite: this.suite,
          garagingAddress: this.garagingAddress,
          dob: this.dob,
          vehicleType: this.vehicleType,
          make: this.make,
          model: this.model,
          bodyStyle: this.bodyStyle,
          vehicleUse: this.vehicleUse,
        }
      }
    },
    methods: {
      async next () {
        let complete = await this.$refs.observer.validate()
        console.log(complete)
        if (!complete) {
          console.log('Error validating form')
        } else {
          this.$store.dispatch('setAutoDetails', this.autoDetails)
          this.$router.push({ path: '/auto/selectplan' })
        }
      // post form data to api
      },
      getAddressData (addressData, placeResultData, id) {
        this.garagingAddress = addressData.name
        this.city = addressData.locality
        this.zip = addressData.postal_code
        this.state = addressData.administrative_area_level_1
      }
    }
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00A1B7;
}

.green-background {
  background-color: #D8FDD7;
}
.sub-title{
  font-size: 20px;
  font-weight: 700;
  color: #00A1B7;
}
.sub-heading {
    font-size: 14px;
    color: #b6b0b0;
}
.v-input--checkbox::v-deep {
  .v-label {
    font-size: 14px;
    font-weight: 700;
    color: #b6b0b0;
  }
}

</style>
